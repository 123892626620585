import React from 'react';
import { Button, Container, Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme=>({
    container:{
        background:theme.colors.light,
        minHeight:'80vh',
        display:'flex',
        justifyContent:'center',
        paddingTop:'20vh'
    },
    content:{
       display:'flex',
       flexDirection:'column',
       alignItems:'center',
       fontFamily:theme.fonts.rubikFont,
       '& $h1':{
           fontSize:'3rem',
           color:theme.colors.lightBlue
       },
       '& $h3':{
           fontSize:'2rem',
           fontWeight:300,
           margin:'20px 0',
           color:theme.colors.lightBlue

       }
        
    }
}))

const ThankUPage = () => {

    const classes = useStyles();


    return ( 

        <div className={classes.container}>
           <div className={classes.content}>
               <h1>Thank You</h1>
               <h3>I recived Your message and I will Contact You soon</h3>
               <Link style={{textDecoration:'none',margin:'20px 0'}} to='/projects'><Button variant='contained' color="primary">Back To Site</Button></Link>
           </div>
        </div>

     );
}
 
export default ThankUPage;