import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch,useSelector } from 'react-redux';
import { Container, TextField,Grid, Select, MenuItem,InputLabel,FormControl,Input ,Button, Typography} from '@mui/material';
import { getAllCategories } from '../../redux/actions/categoriesActions';
import FormInput from '../../components/forms/formInput';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FormSelect from '../../components/forms/formSelect';
import { Link } from 'react-router-dom';
import { postNewProject } from '../../redux/actions/projectsActions';

const useStyles = makeStyles(theme=>({
    formRow:{
        margin:'20px 0',
        display:'flex',
        justifyContent:'space-between'
    },
    imageSection:{
        margin:'40px 0',
        textAlign:'center',
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center'
    }
}))

const NewProject = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const categories = useSelector(state=>state.categoriesReducer.categories);
    const [project,setProject] = React.useState({});
    const [errors,setErrors] = React.useState({});
    const [text,setText] = React.useState('');
    const [image,setImage] = React.useState(null);

    React.useEffect(()=>{
        dispatch(getAllCategories());
    },[])

    const handleChange = (e)=>{
        setProject({...project,[e.target.name]:e.target.value});
    }

    const handleImage = (e)=>{
        let file = e.target.files[0];
        setProject({...project,image:file});
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e)=>{
            setImage(e.target.result);
        }
    }


    const validate = ()=>{
        let error = {};
        let valid = true;

        if(!project.title || project.title === ''){
            error.title = '* require';
            valid = false;
        }
        if(!project.category || project.category === ''){
            error.category = '* require';
            valid = false;
        }
        if(!project.position || project.position === ''){
            error.position = '* require';
            valid = false;
        }
        if(!project.url || project.url === ''){
            error.url = '* require';
            valid = false;
        }
        if(!text || text === ''){
            error.description = '* require';
            valid = false;
        }
        if(!project.image || project.image === ''){
            error.image = '* require';
            valid = false;
        }

        setErrors(error);
        return valid;
    }


    const handleSubmit = (e)=>{
        e.preventDefault();
        if(validate()){
            
            const data = new FormData();
            data.append('title',project.title);
            data.append('categorie_id',project.category);
            data.append('description',text);
            data.append('image',project.image);
            data.append('url',project.url);
            data.append('position',project.position);
            data.append('github',project.github ? project.github : '' );

            dispatch(postNewProject(data));

        }
    }

    return ( 
       
           <Grid container >
               
               <Grid item xs={12} style={{textAlign:'center',margin:'20px 0'}}>
                   <Typography  variant="adminHeader">Add New Project</Typography>
               </Grid>
               
               <Grid item xs={11} md={10} style={{margin:'auto'}}  >
                   <form onSubmit={handleSubmit} >
                       
                        <FormInput name="title" label='Title' fn={handleChange} error={errors.title} />
                        
                        <Grid container className={classes.formRow} >
                            <Grid item xs={11} md={5}>
                                <FormSelect label='Category' id='category' name='category' value={project.category} fn={handleChange} items={categories} error={errors.category} />
                            </Grid>
                            <Grid item xs={11} md={5}>
                                <FormInput name="position" label='Position' type='number' fn={handleChange} error={errors.position} />
                            </Grid>
                        </Grid>
                       

                       <Grid container className={classes.formRow}>
                           <Grid item xs={11} md={5}>
                                <FormInput name="url" label='Url' fn={handleChange} error={errors.url} />

                           </Grid>
                           <Grid item xs={11} md={5}>
                                <FormInput name="github" label='Github' fn={handleChange}  />
                           </Grid>
                       </Grid>

                        <div className={classes.imageSection}>

                                <label htmlFor="contained-button-file">
                                    <Input accept="image/*" id="contained-button-file" multiple type="file" style={{display:'none'}} onChange={handleImage} />
                                    <Button variant="contained" component="span">
                                        Upload Image
                                    </Button>
                                    {errors.image && <p style={{color:'red'}}>{errors.image}</p>}
                                </label>

                                {image && <img src={image} height="100px" alt="project image" />}

                        </div>

                        <CKEditor 
                            editor={ClassicEditor} 
                            data = {text}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setText(data);
                            } }    
                        />

                        {errors.description && <p style={{color:'red'}}>{errors.description}</p>}
                                   
                        <div style={{margin:'40px 0',display:'flex',justifyContent:'space-around'}} >
                            <Link to="/admin/projects" style={{textDecoration:'none'}}> <Button type="button" variant="outlined" color='error'>Cancel</Button></Link>
                            <Button type="submit" color="primary" variant="contained">Save</Button>
                        </div>

                   </form>
               </Grid>


              
           </Grid>
       
     );
}
 
export default NewProject;