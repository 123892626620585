import axios from 'axios';
import { server_url } from "../../utils/config";


export const getAllMessages = ()=>async(dispatch)=>{

    const res = await axios.get(`${server_url}/messages/get-all-messages`);
    
    if(res.data.status){
        dispatch({
            type:'getAllMessages',
            payload:res.data.messages
        })
    }

}


export const changeMessageStatus = (id,data,unread = false)=>async(dispatch)=>{
    
    const res = await axios.post(`${server_url}/messages/change-message-status/${id}`,data);
    if(res.data.status && unread){
        dispatch({
            type:'changeMessageStatus',
            payload:{id:id,data:data}
        })
    }

}

export const getUnReadMessagesNumber = () =>async(dispatch)=>{
    
    const res = await axios.get(`${server_url}/messages/get-unread-messsages-number`);
    dispatch({
        type:'getUnReadMessagesNumber',
        payload:res.data
    })
}


export const postMessage = (data)=>async(dispatch)=>{

    const res = await axios.post(`${server_url}/messages/post-new-message`,data);
    if(res.data.status){
        alert(res.data.message);
        window.location = './thank';
    }
}



export const deleteMessage = (id)=>async(dispatch)=>{
    
    const res = await axios.get(`${server_url}/messages/delete-message/${id}`);
    if(res.data.status){
        alert(res.data.message);
        window.location ='./admin/messages';
    }
}