import jwtDecode from "jwt-decode"

const initialState = {
    user:localStorage.getItem('gwd') ? jwtDecode(localStorage.getItem('gwd')) : null,
    error:null,

}


export const usersReducer = (state=initialState,action)=>{
    
    switch(action.type){

        case 'errorSignin':
            return{
                ...state,
                error:action.payload
            }


        case 'login':
            return{
                ...state,
                error:null
            }



        default: return state;

    }
}