import React from 'react'
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme=>({
    categoryCardContainer:{
        
        height:'250px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        margin:'40px 0',
        overflow:'hidden',
        cursor:'pointer',
        transition:'all ease 0.2s',
        [theme.breakpoints.down('sm')]:{
            margin:'10px 0',
           
         },
        
        "&:hover":{
            transform:'scale(1.1)',
        },
        "&:hover $p":{
            transform:'translateY(0)',
        },
        "& $i":{
            fontSize:'10em',
            [theme.breakpoints.down('sm')]:{
                fontSize:'8em',
               
             },
        },
        "& $p":{
            fontFamily:theme.fonts.rubikFont,
            marginTop:'10px',
            fontWeight:300,
            fontSize:'20px',
            transform:'translateY(200%)',
            transition:'all ease 0.2s',
            [theme.breakpoints.down('sm')]:{
                transform:'translateY(50%)'
               
             },
        }
    }
}))

const CategoryCard = ({category}) => {

    const classes = useStyles();
    

    return ( 
        <div className={classes.categoryCardContainer}>
            <Link to={`/projects/${category.cat_title.toLowerCase().replace(/\s/g,'-')}`} state={category}>
                <i className={category.logo} style={{color:category.color}}></i>
            </Link>
            <p>{category.cat_title.toUpperCase()}</p>
        </div>
     );
}
 
export default CategoryCard;