import React from 'react'
import { makeStyles } from '@mui/styles';
import { Button, Container, Grid } from '@mui/material';
import ImageGallery from '../components/imageGallery';
import { Link } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useDispatch } from 'react-redux';
import {postNewLog} from '../redux/actions/logsActions';

const useStyles = makeStyles(theme=>({
    aboutContainer:{
        width:'100%',
        minHeight:'80vh',
        background:theme.colors.light,
        fontFamily:theme.fonts.raleFont,
        color:theme.colors.midBlue,
        [theme.breakpoints.down('md')]:{
           padding:'0 10px',
          
         },
        '& $h3':{
            fontSize:'2.5rem',
            fontWeight:300,
            [theme.breakpoints.down('md')]:{
                fontSize:'1.5rem'
               
              }
        },
        '& $h1':{
            fontSize:'3rem',
            fontWeight:700,
            [theme.breakpoints.down('md')]:{
                fontSize:'1.6rem'
               
              }
        },
        '& $p':{
            fontSize:'1.2rem',
            fontWeight:400,
            margin:'20px 0',
            lineHeight:'1.5',
            
        },
        
    },
    gallery:{
        [theme.breakpoints.down('sm')]:{
            display:'none'

         },
    },
    downloadBtn:{
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            textAlign:'center',
            margin:'30px 0'
           
         },
    },
    mobileImage:{
        width:'100%',
        margin:'20px 0',
        [theme.breakpoints.up('sm')]:{
           display:'none',
         },
         '& $img':{
             width:'100%',
             boxShadow:'1px 1px 8px black'
         }
    }

}))


const About = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return ( 
       <div className={classes.aboutContainer} >
           <Container maxWidth="xl">
                <Grid container style={{paddingTop:'10vh',display:'flex',justifyContent:'space-between'}}>
                    <Grid item sm={12} md={6}>

                        <h3>Gal Mizrahi</h3>
                        <h1>Full Stack Web Developer</h1>
                        <p>Certificate studies as a Full Stack web development. Server side & Client side using technologies: HTML, CSS, JavaScript, PHP, node js, mongoDB, MySql, React, Angular, Laravel and Bootstrap</p>
                        <p>
                        In Addition i have been study Graphic Designe, 3D Animation and Video editing using Adobe software: Photoshop, Illustrator, Premier Pro, After Effects, InDesign, 3ds Studio max
                        </p>
                        <div className={classes.downloadBtn}>
                            <a  style={{textDecoration:'none'}} href='./files/CV_EN_full_stack_Gal_Mizrahi.docx' download>
                                <Button onClick={()=>dispatch(postNewLog('Download CV'))}  variant="contained" color='warning' endIcon={<FileDownloadIcon />}> Download Resume</Button>
                            </a>
                        </div>
                    </Grid>
                    <Grid className={classes.gallery} item sm={12} md={5}>
                        <ImageGallery/>
                    </Grid>

                    <div className={classes.mobileImage}>
                        <img src="./images/gallery/hackeru.png"  alt="full stack diploma" />
                    </div>


                </Grid>
           </Container>
       </div>
     );
}
 
export default About;