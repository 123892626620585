import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog({button,header,text='',yes,no,fn,color='primary',variant='outlined',data = null}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant={variant} color={color} onClick={handleClickOpen}>
       {button}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        <DialogTitle id="alert-dialog-title" style={{minWidth:'500px'}}>
          {header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{no}</Button>
          {data ?
          <Button variant='contained' onClick={()=>{fn(data);handleClose()}} autoFocus>{yes}</Button> 
          :
          <Button variant='contained' onClick={()=>{fn();handleClose()}} autoFocus>{yes}</Button>
        }
        </DialogActions>
      </Dialog>
    </div>
  );
}
