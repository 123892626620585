import axios from 'axios';
import { server_url } from "../../utils/config";


export const signinUser = (data)=>async(dispatch)=>{

    const res = await axios.post(`${server_url}/users/signin`,data);
    if(!res.data.status){
        dispatch({
            type:'errorSignin',
            payload:res.data.message
        })
    }else{
        const token = res.data.token;
        localStorage.setItem('gwd',token);
        dispatch({
            type:'login',
        })
        window.location = './';
    }

}


