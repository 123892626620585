import { Route, Routes } from 'react-router-dom';
import './App.css';
import {Home,About, Categories, Projects, Contact, SignIn, ThankUPage, PageNotFound} from './pages';
import {Footer, Topbar} from './components';
import {Admin} from './admin';

function App() {
  return (
    <div className="App">
      <header>
       {/* <Navbar/> */}
        <Topbar/>
      </header>

      <main>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/projects' element={<Categories/>}/>
          <Route path='/projects/:categoryName' element={<Projects/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/signin' element={<SignIn/>}/>
          <Route path='/thank' element={<ThankUPage/>}/>
          <Route path='/admin/*' element={<Admin/>}/>
          <Route path='*' element={<PageNotFound/>}/>
        </Routes>
      </main>


      <footer>
        <Footer/>
      </footer>
     
    </div>
  );
}

export default App;
