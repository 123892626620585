import { createStore,applyMiddleware,compose,combineReducers } from "redux";
import thunk from 'redux-thunk';

//reducers
import { categoriesReducer } from "./reducers/categoriesReducer";
import { projectsReducer } from "./reducers/projectsReducer";
import { usersReducer } from "./reducers/usersReducer";
import { messagesReducer } from "./reducers/messagesReducer";
import { logsReducer } from "./reducers/LogsReducer";




const middlewares = [thunk];
const initialState = {};

const rootReducer = combineReducers({
    categoriesReducer,
    projectsReducer,
    usersReducer,
    messagesReducer,
    logsReducer
});

const store = createStore(rootReducer,initialState,compose(
    applyMiddleware(...middlewares),
    //  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

));



export default store;