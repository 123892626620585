import * as React from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper, Container, Typography, Divider,Grid,Button} from '@mui/material';
import {useDispatch,useSelector} from 'react-redux';
import {deleteLog, getAllLogs} from '../../redux/actions/logsActions';
import ConfirmDialog from '../../components/modal/confirmDialog'; 
import {Link} from 'react-router-dom';


export default function AdminLogs() {

    const dispatch = useDispatch();
    const logs = useSelector(state=>state.logsReducer.logs);

    React.useEffect(()=>{
        dispatch(getAllLogs());
    },[])


    const removeLog = (id)=>{
       dispatch(deleteLog(id));
    }


  return (

    <Container style={{margin:'20px auto'}}>

        <Typography variant='adminHeader' >Logs</Typography>
        <Divider style={{margin:'20px 0 40px 0'}} />

        <TableContainer component={Paper}>
            
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
            <TableRow>
                <TableCell>Log ID</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Action</TableCell>
                <TableCell align="left">Delete</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {logs && logs.map((log) => (
                <TableRow key={log.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">{log.id}</TableCell>
                <TableCell align="left">{new Date(log.created_at).toLocaleString()}</TableCell>
                <TableCell align="left">{log.action}</TableCell>
                <TableCell align="left">
                    <ConfirmDialog button='Delete' header='Delete Log?' yes='Delete' no='Cancel' color='error'  variant='contained' fn={removeLog} data={log.id} />
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    

        <Divider style={{margin:'20px 0'}} />
            <Grid item xs={12} style={{display:'flex',justifyContent:'center',margin:'10px 0'}}>
                    
                    <Link to='/admin' style={{textDecoration:'none'}}><Button>Return</Button></Link>
                </Grid>
    </Container>
  );
}
