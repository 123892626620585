import axios from 'axios';
import { server_url } from "../../utils/config";


export const getAllLogs = ()=>async(dispatch)=>{

    const res = await axios.get(`${server_url}/logs/get-all-logs`);
    
    if(res.data.status){
        dispatch({
            type:'getAllLogs',
            payload:res.data.logs
        })
    }

}


export const postNewLog = (action)=>async(dispatch)=>{
    
    await axios.get(`${server_url}/logs/post-new-log/${action}`);
    return;

}




export const deleteLog = (id)=>async(dispatch)=>{

    const res = await axios.get(`${server_url}/logs/delete-log/${id}`);
    if(res.data.status){
       dispatch({
           type:'deleteLog',
           payload:id,
       })
    }
}