import React from 'react'
import { FormControl,InputLabel,Select,MenuItem,FormHelperText } from '@mui/material';

const FormSelect = ({label,id,name,value,fn,items,error=null,first=null}) => {
    return ( 
        <FormControl fullWidth error={error}>
        <InputLabel id="category">{label}</InputLabel>
        <Select
            labelId={id}
            id={id}
            value={value}
            label={label}
            onChange={fn}
            name={name}
            variant='standard'
            error={error}
            
        >
            {first && <MenuItem value={first}>{first.toUpperCase()}</MenuItem>}
            {items.map(item=>(
                <MenuItem key={item.id} value={item.id} style={{display:'flex',alignItems:'center',color:item.color}}>
                    <i className={item.logo}></i> 
                    <span style={{margin:'0 10px'}}>{item.cat_title}</span>
                </MenuItem>
            ))}
        
        </Select>
        <FormHelperText>{error}</FormHelperText>

    </FormControl>
     );
}
 
export default FormSelect;