import React from 'react'
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import LogoAnimation from '../components/logos';
import {icons} from '../utils/icons';
import { Link } from 'react-router-dom';


const useStyles = makeStyles(theme=>({
    homePageContainer:{
        background:theme.colors.lightBlue,
        minHeight:'80vh',
        position:'relative',
        width:'100%',
        
    },
    title:{
        padding:'15vh 0 55vh 10%',
        color:'white',
        [theme.breakpoints.down('md')]:{
            padding:'10vh 10% 0 10%',
         },
        "& $h2":{
            fontFamily:theme.fonts.raleFont,
            fontWeight:300,
            fontSize:'3rem',
            [theme.breakpoints.down('md')]:{
                fontSize:'2rem',
             }
            
        },
        "& $h1":{
            fontFamily:theme.fonts.raleFont,
            fontWeight:900,
            fontSize:'5rem',
            [theme.breakpoints.down('md')]:{
                fontSize:'3rem',
             }
        }
    },
    contactBtn:{
        background:theme.colors.yellow,
        color:theme.colors.lightBlue,
        marginTop:'50px',
        fontFamily:theme.fonts.mainFont,
        fontWeight:700,
        fontSize:'1.6rem',
    },
    logos:{
        position:'absolute',
        zIndex:2,
        bottom:0,
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        background:theme.colors.mainColor,
        width:'100%',
        padding:"20px 0",
        margin:'50px 0 0 0 ', 
        [theme.breakpoints.up('lg')]:{
            display:'none',
         }

    },
    icon:{
        color:'white',
        margin:'5px 5px',
        '& $i':{
            fontSize:'2rem',
        },
        
    },
    animation:{
        position:'absolute',
        zIndex:2,
        top:'15vh',
        right:'5%',
        width:'500px',
        height:'400px',
        [theme.breakpoints.down('lg')]:{
            display:'none',
            
        }
    }
}))


const Home = () => {

    const classes = useStyles();

    return ( 
       <div className={classes.homePageContainer}>

            <div className={classes.title}>
                    <h2>Gal Mizrahi</h2>
                    <h1>Web Developer</h1>
                    <div>
                    <Button variant='contained' className={classes.contactBtn} style={{background:"#FFB703",marginTop:'10px',color:'#023047'}}> <Link to="/contact" style={{textDecoration:'none'}}> Contact Me</Link></Button>
                    
                    </div>
            </div>

            <div className={classes.animation}>
                <div style={{position:'absolute',top:'50%',right:'20%',zIndex:10,transform:'translate(-50%,-50%)'}}>
                    <LogoAnimation data={icons} id="first" start={0} time={6.3} size={13} />
                </div>
                <div style={{position:'absolute',top:"10%",right:'5%',zIndex:20}}>
                    <LogoAnimation data={icons} id="second" start={3} time={4.3} size={5} />
                </div>
                <div style={{position:'absolute',top:"80%",right:'15%',zIndex:30}}>
                    <LogoAnimation data={icons} id="third" start={5} time={3.2} size={8} />
                </div>
                <div style={{position:'absolute',top:"20%",right:'98%',zIndex:40}}>
                    <LogoAnimation data={icons} id="forth" start={2} time={2.8} size={5} />
                </div>
            </div>


           

            <div className={classes.logos} >
                {icons.map((icon,index)=>(
                    <div key={index} className={classes.icon}>
                        <i className={icon.class} style={{color:icon.color}}></i>
                        {/* <p>{icon.title}</p> */}
                    </div>
                ))}
            </div>

       </div>
     );
}
 
export default Home;