import * as React from 'react'
import { makeStyles } from '@mui/styles';
import { Container, Grid,Divider} from '@mui/material';
import { useDispatch,useSelector } from 'react-redux';
import { getAllCategories } from '../redux/actions/categoriesActions';
import CategoryCard from '../components/categoryCard';


const useStyles = makeStyles(theme=>({
    projectsContainer:{
        background:theme.colors.light,
        width:'100%',
        minHeight:'80vh',
    },
    title:{
        padding:'30px 0',
        fontFamily:theme.fonts.raleFont,
        fontSize:'6em',
        fontWeight:300,
        textAlign:'center',
        color:theme.colors.lightBlue,
        [theme.breakpoints.down('md')]:{
            fontSize:'3em',
           
         },
    }
}))


const Categories = () => {
    
    const classes = useStyles();
    const dispatch = useDispatch();
    const categories = useSelector(state=>state.categoriesReducer.categories);

    React.useEffect(()=>{
        dispatch(getAllCategories());
    },[])

    return ( 
        <div className={classes.projectsContainer}>
            <Container maxWidth="lg">
                <h1 className={classes.title}>My Projects</h1>
                <Divider style={{margin:'20px'}}/>
                
                <Grid container style={{display:'flex',justifyContent:'space-around'}}>
                    {categories && categories.map(category=>(
                        <Grid key={category._id} item sm={6} md={3}>
                            <CategoryCard category={category}/>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
     );
}
 
export default Categories;