import * as React from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { getAllMessages } from "../../redux/actions/messagesActions";
import MessagesTable from './messagesTable';
import { changeMessageStatus,getUnReadMessagesNumber } from '../../redux/actions/messagesActions';
import {Container, Grid, Typography,Divider,Button} from '@mui/material';
import {Link} from 'react-router-dom';

const AdminMessages = () => {

    const dispatch = useDispatch();
    const messages = useSelector(state=>state.messagesReducer.messages);


    React.useEffect(()=>{
        dispatch(getAllMessages());
    },[])

    const unRead = (id)=>{
        const data = new FormData();
        data.append('status',0);
        dispatch(changeMessageStatus(id,data,true));
        
    }


    return ( 
        <Container>

            <Grid container>
                <Grid item xs={12} style={{margin:'30px 0'}}>
                    <Typography variant="adminHeader">Messages</Typography>
                </Grid>
                <Grid item xs={12}>
                    <MessagesTable messages={messages} unRead={unRead}/>
                </Grid>
            </Grid>

            <Divider style={{margin:'20px 0'}} />
            <Grid item xs={12} style={{display:'flex',justifyContent:'center',margin:'10px 0'}}>
                    
                    <Link to='/admin' style={{textDecoration:'none'}}><Button>Return</Button></Link>
                </Grid>
        </Container>

     );
}
 
export default AdminMessages;