import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import ProjectCard from '../projectCard';
import { Grid,Container,Button, Divider } from '@mui/material';
import { server_url } from '../../utils/config';
import { makeStyles } from '@mui/styles';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useDispatch } from 'react-redux';
import {postNewLog} from '../../redux/actions/logsActions';


const useStyles = makeStyles(theme=>({
  box:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '70%',
    background: theme.colors.light,
    border: '2px solid #000',
    boxShadow: 24,
    padding:'20px',
    [theme.breakpoints.down('sm')]:{
      width:'95%',
      padding:'5px',
   },
  },
    imageContainer:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        [theme.breakpoints.down('sm')]:{
         display:'none'
       },
        "& $img":{
            width:'100%',
            height:'100%',
            objectFit:'cover'
        }
    },
    dataContainer:{
        paddingLeft:'50px',
        fontFamily:theme.fonts.mainFont,
        color:theme.colors.lightBlue,
        [theme.breakpoints.down('sm')]:{
          overflow:'scroll',
          maxHeight:'70vh',
          width:'100%',
          padding:'20px 5px'
       },
        "& $h2":{
            fontSize:'40px'
        },
        "& $h4":{
            fontSize:'22px',
            fontWeight:300,
        },
    },
    description:{
        margin:'20px 0',
        "& $p":{
            fontWeight:300,
            margin:'10px 0',
            fontSize:'17px',
            color:theme.colors.midBlue
        },
        "& $h4":{
            fontWeight:700,
            color:theme.colors.midBlue,
            fontSize:'22px'
        }
       
    },
}))

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   minWidth: '70%',
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   padding:'20px',
//   [theme.breakpoints.down('sm')]:{
//     width:'100%',
//  },
  
  
  
// };

export default function SingleProductModal({project}) {
  
  const dispatch = useDispatch();
  const classes = useStyles();  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  

  return (
    <div>
      <div onClick={handleOpen}> <ProjectCard project={project}/> </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box  className={classes.box}>
            <Container>
                <Grid container >
                    <Grid item sm={12} md={3} className={classes.imageContainer}>
                        <img src={`${server_url}/${project.image}`} width="100%" alt={project.title} />
                    </Grid>
                    <Grid item sm={12} md={9} className={classes.dataContainer}>
                        <h2>{project.title}</h2>
                        <h4><i className={project.logo}></i> |  {project.cat_title.toUpperCase()}  </h4>
                        <Divider style={{margin:'10px'}}/>
                        <p className={classes.description} dangerouslySetInnerHTML={{ __html: project.description }}></p>
                    </Grid>
                    <Grid item sm={12} style={{display:'flex',justifyContent:'right',margin:'10px'}}>
                       {project.github && <Button variant='contained' onClick={()=>dispatch(postNewLog(`${project.title}-github`))}  style={{background:'black',marginRight:'30px'}} > <a href={project.github} target="_blank" style={{textDecoration:'none',color:'white'}}><GitHubIcon/></a> </Button>}
                       <Button onClick={()=>dispatch(postNewLog(project.title))} variant='contained' color="primary"> <a href={project.url} target="_blank" style={{textDecoration:'none',color:'white'}}>To Project</a> </Button>
                        
                    </Grid>
                </Grid>
                
            </Container>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
