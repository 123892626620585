import axios from 'axios';
import { server_url } from "../../utils/config";


export const getAllProjects = ()=>async(dispatch)=>{
    
    // const res = await axios.get(`${server_url}/projects/get_all_projects`);
    const res = await axios.get(`${server_url}/projects/get_all_projects`);
    dispatch({
        type:'getAllProjects',
        payload:res.data
    })

}


export const getProductsByCategory = (id)=>async(dispatch)=>{

    const res = await axios.get(`${server_url}/projects/get-projects-by-category/${id}`);
    
    if(res.data.status){
        dispatch({
            type:'getProductsByCategory',
            payload:res.data.projects
        })
    }else{
        window.location = './page-not-found';
    }

}



export const postNewProject = (project)=>async(dispatch)=>{
    const res = await axios.post(`${server_url}/projects/post-new-project`,project);
    if(res.data.status){
        alert(res.data.message);
        window.location = './admin/projects';
    }else{
        alert(res.data.message);
    }
}

export const updateProject = (projectId,project)=>async(dispatch)=>{
    const res = await axios.post(`${server_url}/projects/update-project/${projectId}`,project);
    if(res.data.status){
        alert(res.data.message);
        window.location = './admin/projects';
    }else{
        alert(res.data.message);
    }
}



export const deleteProject = (id)=>async(dispatch)=>{

    const res = await axios.get(`${server_url}/projects/delete-project/${id}`);
    if(res.data.status){
        alert(res.data.message);
        window.location = './admin/projects';
    }else{
        alert(res.data.message);
    }
}