import { Container, Grid, Typography,Divider,Button } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import FormInput from '../../components/forms/formInput';
import { useDispatch } from 'react-redux';
import { postNewCategory } from '../../redux/actions/categoriesActions';



const NewCategory = () => {

    const dispatch = useDispatch();
    const [category,setCategory] = React.useState({color:'#000000'});
    const [errors,setErrors] = React.useState({});


    const handleChange = (e)=>{
        setCategory({...category,[e.target.name]:e.target.value});
    }


    const handleSubmit = (e)=>{
        e.preventDefault();
        if(validate()){
            const data = new FormData();
            data.append('cat_title',category.cat_title);
            data.append('logo',category.logo);
            data.append('position',category.position);
            data.append('color',category.color);
            dispatch(postNewCategory(data));
        }
    }


    const validate  = ()=>{
        let valid = true;
        let error = {};

        if(!category.cat_title || category.cat_title === ''){
            error.cat_title = '* Require';
            valid = false;
        }
        if(!category.logo || category.logo === ''){
            error.logo = '* Require';
            valid = false;
        }
        if(!category.position || category.position === ''){
            error.position = '* Require';
            valid = false;
        }
        
        setErrors(error);
        return valid;
    }

    return ( 
        <Container>
            <Grid container >
                <Grid item sm={12} style={{textAlign:'center',margin:'30px 0'}}>
                    <Typography variant='adminHeader'>New Category</Typography>
                </Grid>
                <Grid item sm={12} md={8} lg={6}  style={{margin:'0 auto'}}>
                    <form onSubmit={handleSubmit}>
                        <FormInput name='cat_title' fn={handleChange} label='Title' error={errors.cat_title}/>
                        
                        <Grid contianer style={{display:'flex',justifyContent:'space-between',margin:'20px 0'}}>
                            <Grid item sm={12} md={5}>
                                <FormInput name='logo' fn={handleChange} label='Logo' error={errors.logo}/>
                            </Grid>
                            <Grid item sm={12} md={5}>
                                <FormInput name='position' fn={handleChange} label='Position' error={errors.position} type='number'/>
                            </Grid>
                        </Grid>

                        <Divider />

                        <Grid container style={{display:'flex',justifyContent:'space-around',alignItems:'center',margin:'20px 0'}}>
                            <Grid item sm={2}>
                                <FormInput name='color' fn={handleChange} label='Color' variant='outlined' error={errors.color} type='color'/>
                               <p style={{textAlign:'center',marginTop:'10px'}}>{category.color}</p>
                            </Grid>
                           
                            <Grid item sm={4}>
                                {category.logo && category.color && 
                                    <i className={category.logo} style={{color:category.color,fontSize:'80px'}}></i>
                                }
                            </Grid>
                        </Grid>

                        <Divider />

                        <Grid container>
                            <Grid item sm={12} style={{display:'flex',justifyContent:'space-around',marginTop:'50px'}}>
                                <Link style={{textDecoration:'none'}} to='/admin/categories'> <Button type='button' variant="outlined" color='error'>Cancel</Button></Link> 
                                <Button type='submit' variant="contained" color='primary'>Save</Button>
                            </Grid>
                        </Grid>
                        
                        

                    </form>
                </Grid>
            </Grid>
        </Container>
     );
}
 
export default NewCategory;