import * as React from 'react'
import { makeStyles } from '@mui/styles';
import { TextField,Grid, Button,Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {postMessage} from '../redux/actions/messagesActions';
import emailjs from 'emailjs-com';

const useStyles = makeStyles(theme=>({
    contactContainer:{
        minHeight:'90vh',
        background:theme.colors.light,
        
    },
    
}))

const Contact = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const [message,setMessage] = React.useState({});
    const [errors,setErrors] = React.useState({});

    const handleChange = (e)=>{
        setMessage({...message,[e.target.name]:e.target.value});
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        if(validate(message)){
          
            emailjs.sendForm('gmail', 'template_gpe65ut', e.target, 'user_pDhjywqYRI3xdZBqvxitJ')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            e.target.reset();


            const data = new FormData();
            data.append('name',message.name);
            data.append('email',message.email);
            data.append('phone',message.phone);
            data.append('message',message.note);
            data.append('is_read',0);

            dispatch(postMessage(data));

        }
    }

    const validate = (data)=>{
        let error = {};
        let valid = true;

        if(!data.name || data.name === ''){
            error.name = "* Please Insert Name";
            valid = false;
        }
        if(!data.email || data.email === ''){
            error.email = "* Please Insert Email Address";
            valid = false;
        }
        if(!data.phone || data.phone === ''){
            error.phone = "* Please Insert Phone Number";
            valid = false;
        }
        if(!data.note || data.note === ''){
            error.note = "* Please Insert  your message";
            valid = false;
        }

        if(!valid){
            setErrors(error);
        }
        return valid;
    }


    return ( 
        <div className={classes.contactContainer}>
            <Grid container style={{display:'flex',justifyContent:'center',paddingTop:'50px'}}>
                <Grid item sm={11} md={6} style={{padding:'0 20px'}} >
                        <form onSubmit={handleSubmit}>
                            <TextField style={{margin:'5px 0'}} id="name" name="name" label="Name" variant="standard" fullWidth onChange={handleChange} helperText={errors.name} error={errors.name ? true : false} />
                            <TextField style={{margin:'5px 0'}} id="email" name="email" type="email" label="Email" variant="standard" fullWidth onChange={handleChange} helperText={errors.email} error={errors.email? true : false}  />
                            <TextField style={{margin:'5px 0'}} id="phone" name="phone" label="phone" variant="standard" fullWidth onChange={handleChange} helperText={errors.phone} error={errors.phone? true : false}/>
                            <TextField style={{margin:'20px 0'}} id="note" name="note" label="note" variant="outlined" multiline rows={10} fullWidth onChange={handleChange} helperText={errors.note} error={errors.note? true : false} />
                          
                            <Divider />
                            <Grid item sm={12} style={{display:'flex',justifyContent:'space-around',marginTop:'40px'}}>
                                <Link to="/"  style={{textDecoration:"none"}}> <Button type="button" color="error" variant="outlined">Cancel </Button></Link>
                                <Button type="submit" color="primary" variant="contained">Send</Button>
                            </Grid>
                            
                        </form>
                </Grid>
            </Grid>

        </div>
     );
}
 
export default Contact;

