const initialState = {
    logs:[],
}


export const logsReducer = (state=initialState,action)=>{
    
    switch(action.type){

        case 'getAllLogs':
            return{
                ...state,
                logs:action.payload
            }

        
        

        case 'deleteLog':
            return{
                ...state,
                logs:state.logs.filter(log=>log.id !== action.payload),
            }

        default: return state;

    }
}