import * as React from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { NavLink,Link } from 'react-router-dom';
import { server_url } from '../utils/config';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import { Divider,Badge, } from '@mui/material';
import { getUnReadMessagesNumber } from '../redux/actions/messagesActions';

const useStyles = makeStyles(theme=>({

    topBarContainer:{
        background:theme.colors.midBlue,
        width:'100%',
        height:'100%',
        display:'flex',
        alignItems:'center',
        padding:'0 100px ',
        [theme.breakpoints.down('md')]:{
            padding:'0 30px ',
         }
       
    },
    links:{
        flex:1,
        display:'flex',
        fontFamily:theme.fonts.rubikFont, 
        "& .link":{
            textDecoration:'none',
            color:theme.colors.yellow,
            fontSize:'20px',
            fontWeight:300,
            margin:'0 20px',
            [theme.breakpoints.down('md')]:{
               display:'none'
            }
        }, 
        "& .linkActive":{
            textDecoration:'none',
            color:theme.colors.light,
            fontSize:'20px',
            fontWeight:700,
            margin:'0 20px',
            [theme.breakpoints.down('md')]:{
               display:'none'
            }
        } 
    },
    mobileMenuContainer:{
        position:'absolute',
        zIndex:'5',
        height:'100vh',
        width:'100%',
        left:0,
        top:0,
        background:'rgba(0,0,0,0)',
        transform:'translateX(-100%)',
        transition:'all ease 0.2s',
        paddingTop:'10vh',
        '& $div':{
            background:theme.colors.midBlue,
            display:'flex',
            flexDirection:'column',
            width:'100%',
            height:'100%',
            fontFamily:theme.fonts.rubikFont,  
            fontSize:'20px',
            paddingTop:'50px',
            fontWeight:300,
            '& .linkMobile':{
                display:'block',
                width:'100%',
                height:'50px',
                display:'flex',
                alignItems:'center',
                padding:'0 50px',
                margin:'10px 0',
                color:theme.colors.yellow,
                textDecoration:'none',
                "&:hover":{
                    color:theme.colors.yellow,
                    background:theme.colors.lightBlue,
                }
            },
            '& .linkActiveMobile':{
                display:'block',
                width:'100%',
                height:'50px',
                display:'flex',
                alignItems:'center',
                padding:'0 50px',
                margin:'10px 0',
                color:'white',
                textDecoration:'none',
                fontWeight:300,
                
            },
            '& .mobileLogo':{
                width:'100%',
                display:'flex',
                alignItems:'center',
                
                '& $img':{
                    width:'40%',
                }
            }
        }
    },
    hamburger:{
        display:'none',
        color:"white",
        transform:'scale(1.5)',
        cursor:'pointer',
        [theme.breakpoints.down('md')]:{
            display:'block'
         }
    },
    adminMenu:{
        position:'absolute',
        zIndex:100,
        top:0,
        right:0,
        width:'100%',
        height:'100vh',
        background:'rgba(0,0,0,0)',
        transform:'translateX(100%)',
        display:'none',
        transition:'all ease 0.2s',
        '& $div':{
            position:'absolute',
            right:0,
            marginTop:'10vh',
            padding:'50px 20px',
            width:'20%',
            height:'100vh',
            background:theme.colors.lightBlue,
            borderLeft:'1px solid white',
            display:'flex',
            flexDirection:'column',
            [theme.breakpoints.down('md')]:{
                width:'100%',
                borderLeft:'none',

             },
            '& .adminLink':{
                fontFamily:theme.fonts.rubikFont,
                color:theme.colors.yellow,
                textDecoration:'none',
                fontSize:'20px',
                fontWeight:300,
                height:'50px',
                cursor:'pointer',
                padding:'0 20px'

            }
            
        }
    }
}))

const TopBar = () => {

  
    const user = useSelector(state=>state.usersReducer.user);
    const unread = useSelector(state=>state.messagesReducer.unread);
    const classes = useStyles();
    const dispatch = useDispatch();

    const openMobile = (value)=>{
        const mobileMenu = document.getElementById('mobileMenuContainer');
        mobileMenu.style.transform = `translateX(${value}%)`;
    }
    
    const handleAdmin = (value,display)=>{
       
        const adminMenu = document.getElementById('adminMenu');
        adminMenu.style.display = `${display}`;
        setTimeout(()=>{
            adminMenu.style.transform = `translateX(${value}%)`;
        },200);
    }

    const logout = ()=>{
        localStorage.removeItem('gwd');
        window.location = './';
    }

    React.useEffect(()=>{
        dispatch(getUnReadMessagesNumber());
    },[])

    return ( 
        <div className={classes.topBarContainer}>
            
            <div className={classes.links}>   
                    <NavLink className={(navData)=>navData.isActive ? "linkActive" : "link"}    to='/'>HOME</NavLink>
                    <NavLink className={(navData)=>navData.isActive ? "linkActive" : "link"}    to='/about'>About Me</NavLink>
                    <NavLink className={(navData)=>navData.isActive ? "linkActive" : "link"}    to='/projects'>My Projects</NavLink>
                    <div className={classes.hamburger}>
                            <MenuIcon onClick={()=>openMobile(0)}/>
                    </div>
            </div>

            <div className={classes.mobileMenuContainer} id="mobileMenuContainer" onClick={()=>openMobile('-100')}>   
                <div>
                    <NavLink className={(navData)=>navData.isActive ? 'linkActiveMobile' : 'linkMobile'}  to='/'>Home</NavLink>
                    <NavLink className={(navData)=>navData.isActive ?'linkActiveMobile' : 'linkMobile'}  to='/about'>About Me</NavLink>
                    <NavLink className={(navData)=>navData.isActive ?'linkActiveMobile' : 'linkMobile'}  to='/projects'>My Projects</NavLink>
                    <NavLink className={(navData)=>navData.isActive ?'linkActiveMobile' : 'linkMobile'}  to='/contact'>Contact Me</NavLink>
                    <div className="mobileLogo">
                        <img  src="./images/GWDYellow.png" alt="GWD logo" />
                    </div>
                </div> 

                
            </div>

            <div className={classes.admin}  >
                {user ? 
                <div style={{display:'flex',alignItems:'center'}}>
                    <Link to='/admin/messages'><Badge badgeContent={unread} color="error" style={{marginRight:'20px'}}><MailIcon style={{color:'white',fontSize:'30px'}} /></Badge></Link>
                    <img src={`${server_url}/uploads/${user.image}`} style={{width:'50px',borderRadius:'50%'}} alt=""  onClick={()=>handleAdmin('0','block')}/>
                </div>
                    :
                    <NavLink className={(navData)=>navData.isActive ?'linkActiveMobile' : 'linkMobile'}  to='/signin'><img src="./images/GWDYellow.png" width='40px' alt="gwd logo" /></NavLink>

                }
            </div>

            <div className={classes.adminMenu} id="adminMenu" onClick={()=>handleAdmin('100','none')} >
                <div>
                    <Link className='adminLink'  to='/admin'>Dashboard</Link>
                    <Link className='adminLink'  to='/admin/categories'>Categories</Link>
                    <Link className='adminLink'  to='/admin/projects'>Projects</Link>
                    <Link className='adminLink'  to='/admin/messages'>Messages</Link>
                    <Link className='adminLink'  to='/admin/logs'>Logs</Link>
                    <Divider style={{border:'1px solid white',margin:"10px 10px"}}/>
                    <Link className='adminLink'  to='#' onClick={()=>{logout()}}>Logout</Link>  
                </div> 
            </div>

        </div>
     );
}
 
export default TopBar;