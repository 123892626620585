import React from 'react'
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme=>({
    footerContainer:{
        minHeight:'15vh',
        background:theme.colors.midBlue,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        padding:'50px'
    },
    links:{
        display:'flex',
        justifyContent:'center',
        "& $a":{
            textDecoration:'none',
            color:theme.colors.darkYellow,
            fontFamily:theme.fonts.raleFont,
            width:'200px',
            textAlign:'center',
            fontSize:'20px',
            borderRight:'1px solid'+ theme.colors.darkYellow
        },
        [theme.breakpoints.down('md')]:{
            flexDirection:'column',
            justifyContent:'center',
            "& $a":{
                borderRight:'none',
                margin:'5px 0'
            }
         }
        
    },
    credit:{
       
        color:theme.colors.yellow,
        fontFamily:theme.fonts.raleFont,
        fontSize:'20px'

    },
    divider:{
        background:'white',
        minHeight:'1px',
        width:'50%',
        margin:'20px'
    }
}))

const Footer = () => {

    const classes = useStyles();

    return ( 
        <div className={classes.footerContainer}>
           <div className={classes.links}>
               <Link to="/">Home</Link>
               <Link to="/projects">My Projects</Link>
               <Link to="/contact">Contact Me</Link>
               <Link to="/about" style={{borderRight:'none'}}>About Me</Link>
           </div>
           <div className={classes.divider}></div>
           <div className={classes.credit}>
                <p>Gal Mizrahi &copy; {new Date().getFullYear()}</p>
           </div>
        </div>
     );
}
 
export default Footer;