import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { dividerClasses, TableHead } from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import MarkunreadIcon from '@mui/icons-material/Markunread';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};






const useStyles= makeStyles(theme=>({
    dot:{
        width:'8px',
        minHeight:'8px',
        background:theme.colors.lightBlue,
        borderRadius:'50%'
    },
    tableHead:{
        background:'black',
        '& $tr':{
            '& $th':{
                color:'white',
                fontFamily:theme.fonts.raleFont,
                fontWeight:400,
                fontSize:'20px'
            }
        }
    },
    tableBody:{
        '& $tr':{
            '& $td':{
                fontFamily:theme.fonts.rubikFont,
                fontSize:'14px',
                fontWeight:400
            }
        }
    }
}))


export default function MessagesTable({messages,unRead}) {
 
  const classes = useStyles();  
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - messages.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: '100%' }} aria-label="custom pagination table">
        <TableHead className={classes.tableHead}>
            <TableRow>
                <TableCell></TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Unread</TableCell>
                
            </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {(rowsPerPage > 0
            ? messages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : messages
          ).map((message) => (
            <TableRow key={message.id} onClick={()=>navigate(`/admin/messages/${message.id}`,{state:message})}>
              <TableCell align='center' width="2%">{message.is_read ? '' : <div className={classes.dot}></div> }</TableCell>
              <TableCell width="25%" component="td" scope="row">{new Date(message.created_at).toLocaleString()}</TableCell>
              <TableCell width="10%" component="td" scope="row">{message.name}</TableCell>
              <TableCell width="15%"component="td" scope="row">{message.email}</TableCell>
              <TableCell width="10%" component="td" scope="row">{message.phone}</TableCell>
              <TableCell width="30%" component="td" scope="row">{message.message.substring(0,50) + '...'}</TableCell>
              {message.is_read === 1 ?
              <TableCell onClick={(e)=>{e.stopPropagation();unRead(message.id)}} width="8%" component="td" scope="row"><MarkunreadIcon/></TableCell>
              :
              <TableCell onClick={(e)=>{e.stopPropagation()}} width="8%" component="td" scope="row"></TableCell>
              }
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={5}
              count={messages.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
