import * as React from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { getProductsByCategory } from '../redux/actions/projectsActions';
import { Container, Divider, Grid,Button } from '@mui/material';
import { Link } from 'react-router-dom';
import SingleProductModal from '../components/modal/singleProjectModal';

const useStyles = makeStyles(theme=>({
    projectsContainer:{
        background:theme.colors.light,
        minHeight:'80vh',
    },
    title:{
        padding:'30px 0',
        fontFamily:theme.fonts.raleFont,
        fontSize:'4em',
        fontWeight:400,
        textAlign:'center',
        [theme.breakpoints.down('sm')]:{
           fontSize:'2.5rem'
         },
        
    }
}))

const Projects = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const location = useLocation();
   
    const {id,cat_title,logo,color} = location.state;
    const projects = useSelector(state=>state.projectsReducer.projects);
   
    

    React.useEffect(()=>{
        dispatch(getProductsByCategory(id));
    },[])

    return ( 
       <div className={classes.projectsContainer}>
           <Container maxwidth = 'xl'>
               <h1 className={classes.title} style={{color:color}}> <i className={logo}></i> {cat_title.toUpperCase()}  <i className={logo}></i></h1>
               <Divider style={{margin:'10px 0'}}/>
               <Grid container style={{display:'flex',justifyContent:'center'}} >
                   {projects && projects.map(project=>(
                       <Grid item sm={6} md={3} lg={3} key={project.id}>
                           <SingleProductModal project={project}/>
                       </Grid>
                   ))}
               </Grid>

                    <Divider />
                <div style={{display:'flex',justifyContent:'center',padding:'30px 0'}}>
                    
                    <Link style={{textDecoration:'none'}} to='/projects'><Button variant="outlined">Return</Button></Link>
                </div>

           </Container>
       </div>
     );
}
 
export default Projects;