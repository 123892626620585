import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch,useSelector } from 'react-redux';
import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import { signinUser } from '../redux/actions/usersActions';

const useStyles = makeStyles(theme=>({
    signinContainer:{
        minHeight:'80vh',
        // background:theme.colors.light,
        textAlign:'center',
        
    },
    logoContainer:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:'100%',
        '& $img':{ 
            width:'10%',
            [theme.breakpoints.down('sm')]:{
                width:'50%',
                
             },
        }
    }
}))

const SignIn = () => {

    const dispatch= useDispatch();
    const classes = useStyles();
    const [user,setUser] = React.useState({});
    const [errors,setErrors] = React.useState({});
    const loginError = useSelector(state=>state.usersReducer.error);

    const handleChange = (e)=>{
        setUser({...user,[e.target.name]:e.target.value});
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        if(validate()){
            const data = new FormData();
            data.append('email',user.email);
            data.append('password',user.password);
            dispatch(signinUser(data))
        }
    }

    const validate = ()=>{
        let error = {};
        let valid = true;
        if(!user.email || user.email === ''){
            error.email = "* Please Insert a Valid Email Address";
            valid = false;
        }
        if(!user.password || user.password === ''){
            error.password = "* Please Insert Password";
            valid = false;
        }

        setErrors(error);
        return valid;

    }

    return ( 
        <div className={classes.signinContainer}>
           
                <Grid container>
                        <Grid item sm={12} style={{marginTop:'8vh'}}>
                            <div className={classes.logoContainer}>
                                <img src="./images/GWDcyan.png" alt="GWD logo" />
                            </div>
                        </Grid>

                        {loginError && 
                        <Grid item sm={12} style={{position:"absolute",top:"15vh",left:'50%',transform:'translate(-50%,-50%)',zIndex:3}}>
                            <p style={{color:'red',fontSize:'30px'}}>{loginError}</p>
                        </Grid>
                        }
                      

                        <Grid item xs={10} md={3} style={{margin:'20px auto'}}>
                            <form onSubmit={handleSubmit}>
                                <TextField style={{margin:'20px 0'}} variant='outlined'  fullWidth label='Email' type='email' helperText={errors.email} error={errors.email} name='email' onChange={handleChange} />
                                <TextField style={{margin:'20px 0'}} variant='outlined'  fullWidth label='Password' type='password' helperText={errors.password} error={errors.password} name='password' onChange={handleChange} />
                                <Button style={{margin:'20px 0'}} color='primary' size='large' type="submit" variant='contained' fullWidth>Sign in</Button>
                            </form>            
                        </Grid>
                </Grid>

         

        </div>
     );
}
 
export default SignIn;