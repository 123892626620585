import React from 'react'
import { Container, Typography,Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme=>({
    linkContainer:{
        display:'flex',
        justifyContent:'space-around',
        
    },
    link:{
        textDecoration:'none',
        margin:'30px',
        color:theme.colors.lightBlue,
        '& $i':{
            fontSize:'7rem',
        },
        '& $p':{
            textAlign:'center',
            fontFamily:theme.fonts.rubikFont,
            color:theme.colors.midBlue,
            marginTop:'10px'
        }
    },
    title:{
        textAlign:'center',
        margin:'30px 0',
        fontFamily:theme.fonts.rubikFont,
        fontSize:'3rem',
        color:theme.colors.lightBlue
    }
}));

const adminLinks = [
    {title:'Categories',url:'/admin/categories',icon:'fa-solid fa-boxes-stacked'},
    {title:'Projects',url:'/admin/projects',icon:'fa-solid fa-code'},
    {title:'Messages',url:'/admin/messages',icon:'fa-solid fa-envelope'},
    {title:'Logs',url:'/admin/logs',icon:'fa-solid fa-book'},
];

const Dashboard = () => {

    const classes = useStyles();

    return ( 
       <Container>
        <h1 className={classes.title}>Admin Panel</h1>
        
        <Grid container style={{display:'flex',justifyContent:'space-around',marginTop:'50px'}}>

            {adminLinks.map((link,index)=>(
                <Grid item xs={5} md={4} lg={3} key={index} className={classes.linkContainer} >
                    <Link to={link.url} className={classes.link} >
                        <i className={link.icon}></i>
                        <p>{link.title}</p>
                    </Link>
                </Grid>
            ))}
        </Grid>
       </Container>
     );
}
 
export default Dashboard;