import * as React from 'react'
import { Link, useLocation } from 'react-router-dom';
import {Button, Divider, Grid} from '@mui/material';
import { useDispatch } from 'react-redux';
import { changeMessageStatus,deleteMessage,getUnReadMessagesNumber } from '../../redux/actions/messagesActions';
import { makeStyles } from '@mui/styles';
import ConfirmDialog from '../../components/modal/confirmDialog';

const useStyles = makeStyles(theme=>({
    container:{
        display:'flex',justifyContent:'space-around',padding:'50px 50px',
        [theme.breakpoints.down('sm')]:{
            flexDirection:'column',
            padding:'20px 10px'
         },
    },
    data:{
        fontFamily:theme.fonts.mainFont,
        fontWeight:400,
        [theme.breakpoints.down('sm')]:{
            paddingBottom:'20px',
            borderBottom:'1px solid black'
         },
    },
    message:{
        fontFamily:theme.fonts.mainFont,
        fontWeight:300,
        [theme.breakpoints.down('sm')]:{
            padding:'20px 10px'
        },
    }
}));


const SingleMessage = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const location  = useLocation();
    const message = {...location.state};
    
    React.useEffect(()=>{
        if(message.is_read === 0){
            const status = new FormData();
            status.append('status',1);
            dispatch(changeMessageStatus(message.id,status));
            dispatch(getUnReadMessagesNumber());
        }
    },[])


    const handleDelete = (id)=>{
        dispatch(deleteMessage(id));
    }
    
    return ( 
        <Grid className={classes.container} container >
            
            <Grid item xs={12} md={4}  className={classes.data}>
                    <h4>Date: {new Date(message.created_at).toLocaleString()} </h4>
                    <h4>Name: {message.name} </h4>
                    <h4>Email: {message.email} </h4>
                    <h4>Phone: {message.phone} </h4>
            </Grid>
            <Grid item sx={12} md={8} className={classes.message}>
                <p>{message.message}</p>
                <Divider style={{margin:'10px 0'}} />
            </Grid>
            <Grid item xs={12} style={{display:'flex',justifyContent:'space-around'}}>
                <Link style={{textDecoration:'none'}} to='/admin/messages'><Button >Return</Button></Link>
                <ConfirmDialog button='Delete' color='error' header='Delete Message' yes='Delete' no='Cancel' fn={handleDelete} data={message.id}/>
            </Grid>

        </Grid>
     );
}
 
export default SingleMessage;