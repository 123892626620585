import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch,useSelector } from 'react-redux';
import { Grid,Input ,Button, Typography, Divider} from '@mui/material';
import { getAllCategories } from '../../redux/actions/categoriesActions';
import FormInput from '../../components/forms/formInput';
import FormSelect from '../../components/forms/formSelect';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from 'react-router-dom';
import {deleteProject, updateProject } from '../../redux/actions/projectsActions';
import {useLocation} from 'react-router-dom';
import { server_url } from '../../utils/config';
import ConfirmDialog from '../../components/modal/confirmDialog';

const useStyles = makeStyles(theme=>({
    formRow:{
        margin:'20px 0',
        display:'flex',
        justifyContent:'space-between'
    },
    imageSection:{
        margin:'40px 0',
        textAlign:'center',
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center'
    }
}))

const EditProject = () => {

    const location = useLocation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const categories = useSelector(state=>state.categoriesReducer.categories);
    const [project,setProject] = React.useState({...location.state});
    const [errors,setErrors] = React.useState({});
    const [text,setText] = React.useState(location.state.description);
    const [image,setImage] = React.useState(`${server_url}/${location.state.image}`);
    

    React.useEffect(()=>{
        dispatch(getAllCategories());
    },[])

    const handleChange = (e)=>{
        setProject({...project,[e.target.name]:e.target.value});
    }

    const handleImage = (e)=>{
        let file = e.target.files[0];
        setProject({...project,image:file});
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e)=>{
            setImage(e.target.result);
        }
    }

    const removeProject = ()=>{
        dispatch(deleteProject(project.id));
    }


    const validate = ()=>{
        let error = {};
        let valid = true;

        if(!project.title || project.title === ''){
            error.title = '* require';
            valid = false;
        }
        if(!project.categorie_id || project.categorie_id === ''){
            error.category = '* require';
            valid = false;
        }
        if(!project.position || project.position === ''){
            error.position = '* require';
            valid = false;
        }
        if(!project.url || project.url === ''){
            error.url = '* require';
            valid = false;
        }
        if(!text || text === ''){
            error.description = '* require';
            valid = false;
        }
        if(!project.image || project.image === ''){
            error.image = '* require';
            valid = false;
        }

        setErrors(error);
        return valid;
    }


    const handleSubmit = (e)=>{
        e.preventDefault();
        if(validate()){
            
            const data = new FormData();
            data.append('title',project.title);
            data.append('categorie_id',project.categorie_id);
            data.append('description',text);
            data.append('image',project.image);
            data.append('url',project.url);
            data.append('position',project.position);
            data.append('github',project.github ? project.github : '' );

            dispatch(updateProject(project.id,data));

        }
    }

    return ( 
       
           <Grid container >
               
               <Grid item xs={12} style={{marginTop:'40px',display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                   <Typography  variant="adminHeader">Edit Project</Typography>
                   <ConfirmDialog  button='Delete Project' header='Delete Project?' color='error' yes='Delete' no='cancel' fn={removeProject} variant="contained" />
               </Grid>
               
               <Grid item xs={11} md={10} style={{margin:'auto'}}  >
                   <Divider style={{margin:'40px 0'}} />
                   <form onSubmit={handleSubmit} >
                       
                        <FormInput name="title" label='Title' fn={handleChange} error={errors.title} value={project.title}/>
                        
                        <Grid container className={classes.formRow} >
                            <Grid item sm={12} md={5}>
                                <FormSelect label='Category' id='category' name='categorie_id' value={project.categorie_id} fn={handleChange} items={categories} error={errors.category} />
                            </Grid>
                            <Grid item sm={12} md={5}>
                                <FormInput name="position" label='Position' type='number' fn={handleChange} error={errors.position} value={project.position} />
                            </Grid>
                        </Grid>
                       

                       <Grid container className={classes.formRow}>
                           <Grid item sm={12} md={5}>
                                <FormInput name="url" label='Url' fn={handleChange} error={errors.url} value={project.url}/>

                           </Grid>
                           <Grid item sm={12} md={5}>
                                <FormInput name="github" label='Github' fn={handleChange}  value={project.github} />
                           </Grid>
                       </Grid>

                        <div className={classes.imageSection}>

                                <label htmlFor="contained-button-file">
                                    <Input accept="image/*" id="contained-button-file" multiple type="file" style={{display:'none'}} onChange={handleImage} />
                                    <Button variant="contained" component="span">
                                        Upload Image
                                    </Button>
                                    {errors.image && <p style={{color:'red'}}>{errors.image}</p>}
                                </label>

                                {image && <img src={image} height="100px" alt="project image" />}

                        </div>

                        <CKEditor 
                            editor={ClassicEditor} 
                            data = {text}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setText(data);
                            } }    
                        />

                        {errors.description && <p style={{color:'red'}}>{errors.description}</p>}
                                   
                        <div style={{margin:'40px 0',display:'flex',justifyContent:'space-around'}} >
                            <Link to="/admin/projects" style={{textDecoration:'none'}}> <Button type="button" variant="outlined" color='error'>Cancel</Button></Link>
                            <Button type="submit" color="primary" variant="contained">Save</Button>
                        </div>

                   </form>
               </Grid>


              
           </Grid>
       
     );
}
 
export default EditProject;