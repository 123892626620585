import React from 'react'
import { TextField } from '@mui/material';

const FormInput = ({name,label,fn,variant='standard',error=false,type='text',value=null}) => {
    return ( 
        
        <TextField 
            name={name} 
            label={label} 
            onChange={fn}
            variant={variant} 
            fullWidth 
            helperText={error} 
            error={error} 
            type={type}
            value={value}
            />

     );
}
 
export default FormInput;