import React from 'react'
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const useStyles = makeStyles(theme=>({
    container:{
        width:'100%',
        minHeight:'80vh',
        background:theme.colors.lightBlue,
        display:'flex',
        flexDirection:'column',
        textAlign:'center',
        paddingTop:'10vh',
    },
    error:{
        fontSize:'10rem',
        fontFamily:theme.fonts.rubikFont,
        color:theme.colors.darkYellow,
        fontWeight:300,
        '& $img':{
            width:'120px'
        }
    },
    text:{
        fontSize:'3rem',
        fontFamily:theme.fonts.rubikFont,
        color:theme.colors.darkYellow,
    },
}))

const PageNotFound = () => {

    const classes = useStyles();
    
    return ( 
        <div className={classes.container}>

                <p className={classes.error}>4 <img src="./images/GWDYellow.png" alt="" /> 4</p>
                <h2 className={classes.text}>The Page Not Found</h2>
                <Link to='./' style={{textDecoration:'none',marginTop:'50px'}} ><Button  variant='outlined'style={{color:'#ffc300',border:'1px solid #ffc300'}} >Back To Site</Button></Link>

        </div>
     );
}
 
export default PageNotFound;