import React from 'react'
import { server_url } from '../utils/config.js';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme=>({
    projectCardContainer:{
        width:'100%',
        height:'200px',
        margin:'40px 0',
        padding:'0 10px',
        borderRadius:'10px',
        cursor:'pointer',
        overflow:'hidden',
        "&:hover $p":{
            transform:'translateY(-100%)',
        },
        [theme.breakpoints.down('sm')]:{
            width:'250px',
            padding:'0',
            margin:'20px',
            position:'relative'

         },

    },
    image:{
        borderRadius:'10px',
        width:'100%',
        height:'100%',
        objectFit:'cover',
        overflow:'hidden',

    },
    title:{
        fontFamily:theme.fonts.raleFont,
        width:'100%',
        textAlign:'center',
        background:'rgba(0,0,0,0.8)',
        transition:'all ease 0.2s',
        color:theme.colors.light,
        fontSize:'1.5em',
        padding:'30px 0',
        [theme.breakpoints.down('sm')]:{
            position:'absolute',
            bottom:0,
            fontSize:'1.2em',
         },
        
    }
}))

const ProjectCard = ({project}) => {
    

    const classes = useStyles();

    return ( 
       
        
        <div className={classes.projectCardContainer}>
            <img className={classes.image} src={`${server_url}/${project.image}`} alt={project.title} />
            <p className={classes.title}>{project.title}</p>
        </div>


     );
}
 
export default ProjectCard;