import * as React from 'react'
import { makeStyles } from '@mui/styles';
import { NavLink, Routes ,Route} from 'react-router-dom';
import {Dashboard,AdminCategories,AdminProjects,NewProject,EditProject,AdminMessages,SingleMessage,NewCategory,EditCategory,AdminLogs} from './index';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme=>({

    adminContainer:{
        width:'100%',
        minHeight:'80vh',
        display:'flex',
        [theme.breakpoints.down('sm')]:{
            flexDirection:'column',
         },
    },
    adminNav:{
        paddingTop:'50px',
        borderTop:'1px solid white',
        borderBottom:'1px solid white',
        display:'flex',
        flexDirection:'column',
        width:'15%',
        minHeight:'80vh',
        background:theme.colors.midBlue,
        fontFamily:theme.fonts.raleFont,
        [theme.breakpoints.down('sm')]:{
            flexDirection:'row',
            minHeight:'50px',
            width:'100%',
            paddingTop:0,
            
         },
        '& .link':{
            color:'white',
            textDecoration:'none',
            display:'flex',
            alignItems:'center',
            height:'50px',
            width:'100%',
            padding:'0 50px',
            [theme.breakpoints.down('sm')]:{
                width:'20%',
                padding: '0',
                fontSize:'12px',
                display:'flex',
                justifyContent:'center'
                
             },
            '&:hover':{
                background:theme.colors.lightBlue,
                color:theme.colors.yellow
            }

        },
        '& .activeLink':{
            color:theme.colors.yellow,
            textDecoration:'none',
            display:'flex',
            alignItems:'center',
            height:'50px',
            width:'100%',
            padding:'0 50px',
            [theme.breakpoints.down('sm')]:{
                width:'20%',
                padding: '0',
                fontSize:'12px',
                display:'flex',
                justifyContent:'center'
             },
            '&:hover':{
                background:theme.colors.lightBlue,
                color:theme.colors.yellow
            }

        }
    },
    adminContent:{
        margin:'0 auto',
        width:'100%'
    }
}))

const Admin = () => {

    const classes = useStyles();
    const admin = useSelector(state=>state.usersReducer.user);
    React.useEffect(()=>{
        if(!admin){
            window.location = '/';
        }
    },[])

    return ( 
        <div className={classes.adminContainer}>
            <div className={classes.adminNav}>
                <NavLink className={(navData)=>navData.isActive ? 'activeLink' : 'link'} to='/admin'>DashBoard</NavLink>
                <NavLink className={(navData)=>navData.isActive ? 'activeLink' : 'link'} to='/admin/categories'>Categories</NavLink>
                <NavLink className={(navData)=>navData.isActive ? 'activeLink' : 'link'} to='/admin/projects'>Projects</NavLink>
                <NavLink className={(navData)=>navData.isActive ? 'activeLink' : 'link'} to='/admin/messages'>Messages</NavLink>
                <NavLink className={(navData)=>navData.isActive ? 'activeLink' : 'link'} to='/admin/logs'>Logs</NavLink>
            </div>
            <div className={classes.adminContent}>
                <Routes>
                    <Route path='/' element={<Dashboard/>} />
                    
                    <Route path='/categories' element={<AdminCategories/>} />
                    <Route path='/categories/add-new-category' element={<NewCategory/>} />
                    <Route path='/categories/edit-category' element={<EditCategory/>} />
                    
                    <Route path='/projects' element={<AdminProjects/>} />
                    <Route path='/projects/new-project' element={<NewProject/>} />
                    <Route path='/projects/edit-project' element={<EditProject/>} />
                    
                    <Route path='/messages' element={<AdminMessages/>} />
                    <Route path='/messages/:messageid' element={<SingleMessage/>} />
                    
                    <Route path='/logs' element={<AdminLogs/>} />
                </Routes>

            </div>
        </div>
     );
}
 
export default Admin;