const initialState = {
    messages:[],
    unread:0,
}


export const messagesReducer = (state=initialState,action)=>{
    
    switch(action.type){

        case 'getAllMessages':
            return{
                ...state,
                messages:action.payload
            }

        
        case 'changeMessageStatus':
            
            let updateMessages = [...state.messages];

           updateMessages.forEach(message =>{
                if(message.id === action.payload.id){
                     message.is_read = action.payload.status;  
                }
                updateMessages.push(message);
                
            })
         
            return{
                ...state,
                messages:updateMessages,
                unread:state.unread + 1,
            }


        case 'getUnReadMessagesNumber':
            return{
                ...state,
                unread:action.payload
            }

        default: return state;

    }
}