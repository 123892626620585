import { createTheme } from "@mui/material/styles";

const mainFont = "'Open Sans', sans-serif";
const rubikFont = "'Rubik', sans-serif";
const raleFont = "'Raleway', sans-serif";


export const myTheme = createTheme({

    fonts:{
        mainFont:mainFont,
        rubikFont:rubikFont,
        raleFont:raleFont,
    },
    colors:{
        mainColor:'#000814',
        blue:"#023E8A",
        midBlue:"#001d3d",
        lightBlue:"#003566",
        yellow:"#ffd60a",
        darkYellow:'#ffc300',
        light:'#CFD6EA'
    },
    typography:{
        adminHeader:{
            fontFamily:rubikFont,
            fontSize:'40px',
            color:"#003566",
            
        }
    }

})