import { Button, Container, Divider, Grid, Typography } from '@mui/material';
import * as React from 'react'
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { getAllCategories } from '../../redux/actions/categoriesActions';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles= makeStyles(theme=>({
     category:{
        textAlign:'center',
        margin:'40px 40px',
        '& $i':{
            fontSize:'8rem',
            [theme.breakpoints.down('sm')]:{
                fontSize:'6rem',
             },
        }
     }
}))

const AdminCategories = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const categories = useSelector(state=>state.categoriesReducer.categories);
    
    React.useEffect(()=>{
        dispatch(getAllCategories());
    },[])

    return ( 
        <Container>
            <Grid container >
                <Grid item xs={12} style={{margin:'20px 0'}}>
                   <Typography variant='adminHeader'>Categories</Typography>
               </Grid>
               
                <Grid item xs={12} md={6}>
                    <Link to="/admin/categories/add-new-category" style={{textDecoration:'none'}}> <Button variant="contained">Add New Category</Button></Link>
                </Grid>
                
                  
               
                
            </Grid>

            <Grid container style={{margin:'40px 0',display:'flex',justifyContent:'center'}}>
                {categories && categories.map(category=>(
                    <Grid item xs={6} md={5} lg={3} key={category.id} onClick={()=>navigate('/admin/categories/edit-category',{state:category})}>
                        <div className={classes.category}>
                            <i className={category.logo} style={{color:category.color}}></i>
                            <p >{category.cat_title}</p>
                        </div>
                       
                    </Grid>
                ))}
            </Grid>

            <Divider style={{marginBottom:'20px'}} />
            <Grid item xs={12} style={{display:'flex',justifyContent:'center',margin:'10px 0'}}>
                    
                    <Link to='/admin' style={{textDecoration:'none'}}><Button>Return</Button></Link>
                </Grid>

        </Container>
     );
}
 
export default AdminCategories;