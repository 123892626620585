import * as React from 'react'
import { makeStyles } from '@mui/styles';



// const icons = [
//     {icon:"fa-brands fa-html5", title:'HTML5',color:'#f8961e'},
//     {icon:"fa-brands fa-css3-alt", title:'CSS3',color:'#a9d6e5'},
//     {icon:"fa-brands fa-js-square", title:'JAVA SCRIPT',color:'#ffba08'},
//     {icon:"fa-brands fa-react", title:'REACT',color:'cyan'},
//     {icon:"fa-brands fa-node-js", title:'Node JS',color:'#70e000'},
//     {icon:"fa-brands fa-php", title:'PHP',color:'#b5179e'},
//     {icon:"fa-solid fa-database", title:'MYSQL / MONGO DB',color:'#ced4da'},
// ]



const useStyles = makeStyles(theme=>({
    
    logo:{
        textAlign:'center',
        transition:'all ease 0.5s',   
        "& $i":{
            fontSize:'15rem',
            color:'white',
        },
        "& $p":{
            color:'white',
            fontFamily:theme.fonts.rubikFont,
            marginTop:'10px',
           
        }
    }

}))


const LogoAnimation = ({data,id,start,time,size}) => {

   
    const classes = useStyles();
    const [index,setIndex] = React.useState(start);

    const run = ()=>{

        let logo = document.getElementById(id);
        logo.style.opacity = 1;
        
        setTimeout(()=>{
            logo.style.opacity = 0;    
        },(time*1000)-1000);
        
        setTimeout(()=>{
           
            if(index > 5){
                setIndex(0);
               
            }else{
                setIndex(index+1);
              
            }
           
        },(time*1000))
        
    
    }
    


React.useEffect(()=>{
     run();
},[index]);

    return ( 
        <div id={id} className={classes.logo}>
            <i className={data[index].class} style={{color:data[index].color,fontSize:size+'rem'}}></i>
            {/* <p>{data[index].title}</p> */}
        </div>
     );
}
 
export default LogoAnimation;