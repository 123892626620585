import axios from 'axios';
import { server_url } from "../../utils/config";


export const getAllCategories = ()=>async(dispatch)=>{

    const res = await axios.get(`${server_url}/categories/get-all-categories`);
    
    if(res.data.status){
        dispatch({
            type:'getAllCategories',
            payload:res.data.categories
        })
    }

}


export const postNewCategory = (data)=>async(dispatch)=>{

    const res = await axios.post(`${server_url}/categories/post-new-category`,data);
    if(res.data.status){
        alert(res.data.message);
        window.location = './admin/categories';
    }
}



export const updateCategory = (id,data)=>async(dispatch)=>{
    const res = await axios.post(`${server_url}/categories/update-category/${id}`,data);
    if(res.data.status){
        alert(res.data.message);
        window.location = './admin/categories';
    }
}


export const deleteCategory = (id)=>async(dispatch)=>{
    const res  = await axios.get(`${server_url}/categories/delete-category/${id}`);
    if(res.data.status){
        alert(res.data.message);
        window.location = './admin/categories';
    }else{
        alert(res.data.message);
    }
}