const initialState = {
    categories:[],
}


export const categoriesReducer = (state=initialState,action)=>{
    
    switch(action.type){

        case 'getAllCategories':
            return{
                ...state,
                categories:action.payload
            }



        default: return state;

    }
}