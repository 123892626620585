import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch,useSelector } from 'react-redux';
import { getAllProjects, getProductsByCategory } from '../../redux/actions/projectsActions';
import { Button, Container, Grid, Typography ,Divider } from '@mui/material';
import ProjectsTable from './projectsTable';
import {getAllCategories} from '../../redux/actions/categoriesActions';
import { Link } from 'react-router-dom';
import FormSelect from '../../components/forms/formSelect';

const AdminProjects = () => {


    const dispatch = useDispatch();
    const projects = useSelector(state=>state.projectsReducer.projects);
    const categories = useSelector(state=>state.categoriesReducer.categories);
    const [filter,setFilter] = React.useState('all');

    React.useEffect(()=>{
        dispatch(getAllProjects());
        dispatch(getAllCategories());
    },[])

    const handleChange = (e)=>{
        let value = e.target.value;
        setFilter(value);
        if(value === 'all'){
            dispatch(getAllProjects()); 
        }else{
            dispatch(getProductsByCategory(value));
        }
    }


    const deleteProject = ()=>{
        
    }

    return ( 
       <Container>
           <Grid container style={{marginBottom:'100px'}}>
               
               <Grid item xs={12} style={{margin:'20px 0'}}>
                   <Typography variant='adminHeader'>Projects</Typography>
               </Grid>
               
               <Grid item sx={12} style={{width:"100%"}} >
                   <Grid container style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                       <Grid item xs={6}>
                            <Link style={{textDecoration:'none'}} to="/admin/projects/new-project"><Button variant='contained'>New Project</Button></Link>
                       </Grid>
                       <Grid item xs={5}>
                            <FormSelect label='Categories' id='categories' fn={handleChange} items={categories} value={filter} name='categories' first="all" />
                       </Grid>
                   </Grid>
               </Grid>

               

               <Grid item xs={12}>
                   <Divider style={{margin:'40px 0'}} />
                   <ProjectsTable projects={projects}/>
                   <Divider style={{margin:'40px 0'}} />
               </Grid>


                <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>
                    <Link to='/admin' style={{textDecoration:'none'}}><Button>Return</Button></Link>
                </Grid>

           </Grid>
       </Container>
     );
}
 
export default AdminProjects;