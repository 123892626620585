const initialState = {
    projects:[],
}


export const projectsReducer = (state=initialState,action)=>{
    
    switch(action.type){

        case 'getAllProjects':
            return{
                ...state,
                projects:action.payload
            }


        case 'getProductsByCategory':
            return{
                ...state,
                projects:action.payload
            }



        default: return state;

    }
}